import { writable } from 'svelte/store';
import { auth } from '$lib/firebase';
import type { User } from 'firebase/auth';

const authStore = writable<{
	user?: User | null;
	isLoggedIn?: boolean;
	firebaseControlled: boolean;
}>({
	user: undefined, // undefined means we haven't determined their sign-in status yet
	isLoggedIn: undefined,
	firebaseControlled: false
});

auth.onIdTokenChanged((user) => {
	authStore.set({
		user: user,
		isLoggedIn: !!user,
		firebaseControlled: true
	});
});

setInterval(() => {
	// refresh token every 10 minutes
	auth.currentUser?.getIdToken(true);
}, 10 * 60 * 1000);

export default {
	subscribe: authStore.subscribe,
	set: authStore.set
};
